// Global 3rd party mixins
import "./plugins/veeValidate";
import "popper.js";
import "tooltip.js";

import Vue from "vue";
import App from "./App.vue";
import store from "@/store";
import router from "./router";
import ReadMore from "vue-read-more";
import globalMixin from "./mixins/index";
import ApiService from "./core/services/api.service";
import {
  UPDATE_USER_TOKEN,
  VERIFY_AUTH
} from "@/store/users/auth.module";
import {
  SWITCH_TEAM
} from "@/store/team/manageTeam.module.js";

// Vue 3rd party mixins
import ClipboardJS from "clipboard";
import Clipboard from "v-clipboard";
import vuetify from "./plugins/vuetify";
import "firebase/analytics";
import firebase from "firebase/app";
import VueTour from "vue-tour";
import Flag from "vue-flagpack";

// sentry integrations
import * as Sentry from "@sentry/vue";
import {
  BrowserTracing
} from "@sentry/tracing";
// vue meta
import Meta from "vue-meta";
import "@/core/filters";
import "@/core/components";
import AxiosConfig from "@/core/axios";
import Logger from "@/core/logger";

require("vue-tour/dist/vue-tour.css");

if (process.env.VUE_APP_ENV !== "local") {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    replaysSessionSampleRate: parseFloat(
      process.env.VUE_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE
    ),
    replaysOnErrorSampleRate: parseFloat(
      process.env.VUE_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
    ),
    release: process.env.VUE_APP_RELEASE,
    environment: process.env.VUE_APP_ENV,
    tracesSampleRate: parseFloat(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE),
    integrations: [
      //new Sentry.Replay(),
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    ignoreErrors: [
      "zaloJSV2 is not defined",
      "Illegal invocation",
      "Can't find variable: zaloJSV2",
      "privateSpecialRepair is not defined",
      "Cannot read properties of null (reading 'style')",
      "Network Error",
      "ChunkLoadError",
      "NotFoundError",
      "Unexpected identifier",
      "InvalidStateError: The object is in an invalid state.",
      "Cannot read properties of null (reading 'document')",
      "window.bannerNight is not a function",
      /^Loading CSS chunk chunk-[a-zA-Z0-9]{8} failed. \(\/css\/chunk-[a-zA-Z0-9]{8}\.[a-zA-Z0-9]{8}\.css\)/,
      "Cannot read properties of undefined (reading '__k')",
      "Cannot read properties of undefined (reading 'url')",
      "null is not an object (evaluating 'e.style')",
      "Invalid or unexpected token",
      "AndroidManifest.xml can't be found.",
      "AbortError: The play() request was interrupted by a call to pause()",
      "Info.plist can't be found.",
      "e is null",
      "e.video is null",
      "null is not an object (evaluating 'e.dialog.isOpen')",
      "Cannot read properties of null (reading 'isOpen')",
      "Installations: Could not process request. Application offline. (installations/app-offline).",
    ],
    denyUrls: [
      /fast\.wista\.com/i,
      /assets\.calendly\.com/i,
      /statc\.cloudflareinsights\.com/i,
      /googletagmanager\.gtag\.com/i,
      "https://static.cloudflareinsights.com/beacon.min.js",
      "https://fast.wistia.com/embed/medias/1r6jns5l8i.jsonp",
      "https://fast.wistia.com/embed/medias/rgvr4l5zf9.jsonp",
      "https://fast.wistia.com/embed/medias/6k5wxen95k.jsonp",
      "https://fast.wistia.com/assets/external/E-v1.js",
      "https://assets.calendly.com/assets/external/widget.js",
    ],
  });
}

window.ClipboardJS = ClipboardJS;

Vue.use(Meta);

Vue.use(VueTour);
Vue.config.productionTip = true;
Vue.use(Clipboard); // this plugin for cpying text to clipboard
Vue.use(ReadMore); // for the readmore feature
Vue.mixin(globalMixin);
Vue.use(Flag, {
  name: "Flag",
});
Vue.use(AxiosConfig);
Vue.use(Logger);

ApiService.init(); // API service init

/** START PADDLE */
if (process.env.VUE_APP_PADDLE_ENV === "sandbox") {
  window.Paddle.Environment.set("sandbox");
}
if (window && window.Paddle) {
  window.Paddle.Setup({
    vendor: parseInt(process.env.VUE_APP_PADDLE_VENDOR),
    eventCallback: function (response) {
      if (response.event == "Checkout.Location.Submit") {
        store.commit("setTaxInformation", {
          tax: response?.eventData?.checkout?.prices,
          country: response?.eventData?.country,
          postcode: response?.eventData?.postcode,
        });
      }

      if (response.event == "Checkout.PaymentComplete") {
        store.commit("setPaymentProcessing", true);
      }
      if (response.event == "Checkout.complete") {
        store.commit("setPaymentProcessing", false);
      }
      if (
        response.event == "Checkout.Coupon.Applied" ||
        (response.eventData.checkout &&
          response.eventData.checkout.coupon &&
          response.eventData.checkout.coupon.coupon_code)
      ) {
        store.commit(
          "setCouponPrice",
          response?.eventData?.checkout?.prices?.customer?.items[0]?.unit_price
        );
      }
      if (response.event == "Checkout.Coupon.Remove") {
        store.commit("setCouponPrice", {});
      }
      store.commit("setTaxInformation", {
        tax: response?.eventData?.checkout?.prices,
        country: store?.getters?.getUserCountry,
        postcode: store?.getters?.getPostCode,
      });
    },
  });
}

/** START FIREBASE */
firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
});

Vue.prototype.$analytics = firebase.analytics();
/** END FIREBASE */

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(() => next());
});

router.afterEach((to, from) => {
  if (to?.query?.sso) {
    store.commit("setSSOAuthQuery", to?.query);
  }
  if (to && to.query && to.query.plan) {
    let description = `Check out our ${to.query.plan}! Sign up for our app testing platform where developers share their app Android (APK) & iOS (IPA) with others & get instant feedback.`;
    document.querySelector("meta[name='description']").content = description;
    let link = document.createElement("link");
    link.rel = "canonical";
    link.href = "https://portal.testapp.io/signup";
    document.head.appendChild(link);
  }
  Vue.nextTick(() => {
    document.title = to.meta.title || "TestApp.io - Portal";
    if (to && to.query && to.query.team_id && to.name !== "mobile-app-check") {
      const currentTeamId = store.getters.getTeamId;
      if (
        to.query.team_id !== currentTeamId &&
        !["integration-source", "integration-source-auth"].includes(to.name)
      ) {
        store
          .dispatch(SWITCH_TEAM, to.query.team_id)
          .then((response) => {
            store.commit("setSwitchModal", true);
            store.dispatch(UPDATE_USER_TOKEN, response.data.token);
            setTimeout(() => {
              window.location.href = window.location.href.split("?")[0];
            }, 2000);
          })
          .catch((err) => {
            store.commit("notifyErrorMessage", err.message);
            window.history.replaceState(null, null, window.location.pathname);
          });
      } else {
        window.history.replaceState(null, null, window.location.pathname);
      }
    }
  });
  if (store && store.getters.isAuthenticated) {
    const getRoleID = store.getters.getRoleID;

    /** Segment */
    if (process.env.VUE_APP_ENV !== "local") {
      window.analytics.page(to.name, {
        path: to.fullPath,
        referrer: from.fullPath,
      });
    }
  }
});

export default new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
