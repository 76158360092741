import Vue from "vue";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import StorageService from "@/core/services/localstorage.service";
import {
  coreApi,
  portalApi
} from "@/core/axios";
import {
  CLEAR_APP
} from "../apps/app.module";
import {
  CLEAR_APPS
} from "../apps/apps.module";
import {
  CLEAR_CREATE_APP
} from "@/store/apps/createApp.module";
import {
  CLEAR_DASHBOARD
} from "@/store/users/dashboard.module";
import {
  CLEAR_UPLOADS
} from "@/store/common/uploads.module";
import {
  CLEAR_APP_AUTOCOMPLETE
} from "@/store/common/util.module";
import {
  CLEAR_TEAM
} from "@/store/team/manageTeam.module";
import {
  SET_APP_CONSTANTS
} from "@/store/common/util.module.js";
import * as Sentry from "@sentry/vue";
import {
  CLEAR_APP_MEMBER
} from "@/store/apps/appmember.module.js";
import {
  CLEAR_NOTIFICATION_DATA
} from "@/store/users/notifications.module.js";
import {
  CLEAR_STORAGE
} from "@/store/team/storage.module.js";
import {
  CLEAR_WHATS_NEW_LIST
} from "@/store/users/whatsNew.module.js";
import {
  CLEAR_RELEASE_DATA
} from "@/store/releases/releases.module.js";
import {
  CLEAR_TIMELINE_DATA
} from "@/store/apps/timeline.module.js";
import {
  CLEAR_TOKEN_LIST
} from "@/store/users/settings.module.js";
import {
  CLEAR_INVITE_DATA
} from "@/store/team/invite.module.js";
import {
  CLEAR_TEAM_MEMBER_DATA
} from "@/store/team/members.module.js";
import {
  isEmpty
} from "@/core/services/helper.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const CLEAR_STORE = "clearStore";
export const SIGNIN = "signin";
export const SSO_AUTH = "signinWithSSO";
export const SIGNIN_WITH_TOKEN = "signinWithToken";
export const SIGNOUT = "signout";
export const SIGNUP = "signup";
export const FORGOT_PASSWORD = "forgotPassword";
export const RESET_PASSWORD = "resetPassword";
export const REFRESH_TOKEN = "refreshToken";
export const PURGE_AUTH_DATA = "purgeAuthData";
export const VERIFY_ACTIVATE = "verifyActivate";
export const VERIFY_CODE = "verifyCode";
export const VERIFY_TOKEN = "verifyToken";
export const RESEND_TOKEN = "reSendToken";
export const ACTIVATE_ACCOUNT = "activateAccount";
export const DELETE_ACCOUNT_VERIFY = "deleteAccountVerify";
export const VERIFY_RESET_PASSWORD_TOKEN = "verifyResetPasswordToken";
export const UPDATE_USER_TOKEN = "updateUserToken";
export const UPDATE_DARK_THEME = "updateDarkTheme";
export const GET_BROADCAST_INFO = "getBroadcastInformation";

export const UPDATE_PROFILE = "updateProfile";
export const DELETE_PROFILE = "deleteProfile";
export const UPDATE_PROFILE_IMAGE = "updateProfileImage";
export const UPDATE_INFO = "updateInfo";
export const DELETE_PROFILE_IMAGE = "deleteProfileImages";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_IDENTITY_FOR_TRACKER = "setIdentityForTracker";
export const REMOVE_IDENTITY_FOR_TRACKER = "removeIdentityForTracker";
export const SET_GLOBAL_CONTEXT_FOR_TRACKER = "setGlobalContextForTracker";
export const UPDATE_ROLES = "setUserRoles";
export const SET_ERROR = "setError";
export const GET_USER_ROLES = "getUserRoles";

const user = StorageService.getItem("user");
// var _hsq = (window._hsq = window._hsq || []);

const state = {
  errors: null,
  user: StorageService.getItem("user"),
  userRoles: {},
  navigateTo: "",
  ssoAuthQuery: null,
  broadCastMessage: {},
  redirectionUrl: {},
  feedbackModalDetail: false,
  inactiveUserMessage: "",
  authMessage: {
    message: "",
    type: "error",
  },
  activateErrorMessage: "",
  queryTeamId: "",
  infoLoaded: false,
  darkTheme: false,
  portalUpdateInfo: {
    showReleaseUpdate: false,
    versionInfo: null,
  },
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  getPortalUpdateInfo(state) {
    return state.portalUpdateInfo;
  },
  getSSOAuthQuery(state) {
    return state.ssoAuthQuery;
  },
  getFeedbackModalDetail(state) {
    return state.feedbackModalDetail;
  },
  getInactiveUserMessage(state) {
    return state.inactiveUserMessage;
  },
  getBroadCastMessage(state) {
    return state.broadCastMessage;
  },
  currentUser(state) {
    return state.user;
  },
  getQueryTeamId(state) {
    return state.queryTeamId;
  },
  getNavigateTo(state) {
    return state.navigateTo;
  },
  getRedirectionUrl(state) {
    return state.redirectionUrl;
  },
  getWhatsNewLastReadId(state) {
    return state.userRoles.last_whats_new;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getAuthMessage(state) {
    return state.authMessage;
  },
  getActivateErrorMessage(state) {
    return state.activateErrorMessage;
  },
  getNotificationsSetting(state) {
    return state.userRoles && state.userRoles.profile ?
      state.userRoles.profile.notification_settings :
      null;
  },
  userRoles(state) {
    return state.userRoles;
  },
  isInfoLoaded(state) {
    return state.infoLoaded;
  },
  getTeamId(state) {
    return state.userRoles && state.userRoles.team ?
      state.userRoles.team.id :
      StorageService.getItem("current_team_id");
  },
  getRoleID(state) {
    return state.userRoles && state.userRoles.team ?
      state.userRoles.team.member_role_id :
      StorageService.getItem("current_team_role_id");
  },
  getIsUserDisabled(state) {
    return state.userRoles && state.userRoles.team ?
      state.userRoles.team.member_status === 2 :
      false;
  },
  getTeamName(state) {
    return state.userRoles && state.userRoles.team ?
      state.userRoles.team.name :
      "";
  },
  getCurrentPlanName(state) {
    return state.userRoles && state.userRoles.team_plan ?
      state?.userRoles?.team_plan?.name :
      "";
  },
  getTeamUsageInformation(state) {
    return state?.userRoles?.team_plan?.usage || {};
  },
  getTeamPlanUsage(state) {
    return state.userRoles && state.userRoles.team_plan ? {
      ...state.userRoles.team_plan.usage.distribution,
    } : {};
  },
  getDiscountMessage(state) {
    return state.userRoles && state.userRoles.team_plan ?
      state.userRoles.team_plan.discount_message || "" :
      "";
  },
  getKycFormStatus(state) {
    if (!isEmpty(state?.userRoles)) {
      return state?.userRoles?.profile?.meta?.kyc;
    } else {
      return true;
    }
  },
  getDarkTheme(state) {
    if (
      state?.userRoles?.profile?.meta?.theme === "" ||
      state?.userRoles?.profile?.meta?.theme === "auto"
    ) {
      // Check if the user's system is in dark mode
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return state.userRoles &&
        state.userRoles.profile &&
        state.userRoles.profile.meta ?
        state.userRoles.profile.meta.theme === "dark" || state.darkTheme :
        false;
    }
  },
  getTeamPlanAnalytics(state) {
    return state.userRoles &&
      state.userRoles.team_plan &&
      state.userRoles.team_plan.usage ? {
        ...state.userRoles.team_plan.usage.analytics,
      } : {};
  },
  getTeamPlanLimits(state) {
    return state.userRoles &&
      state.userRoles.team_plan &&
      state.userRoles.team_plan.usage ? {
        ...state.userRoles.team_plan.usage.limits,
      } : {};
  },
  getTeamTrial(state) {
    return state.userRoles &&
      state.userRoles.team_plan &&
      state.userRoles.team_plan.trial ? {
        ...state.userRoles.team_plan.trial,
      } : {};
  },
  getCurrentPlan(state) {
    return state.userRoles && state.userRoles.team_plan ?
      state.userRoles.team_plan :
      "";
  },
  userPermissions(state) {
    return state.userRoles && state.userRoles.permissions ? {
      ...state.userRoles.permissions,
    } : {};
  },
  getAssets(state) {
    return state.userRoles.assets;
  },
  userNotificationsStats(state) {
    return state.userRoles && state.userRoles.notifications ? {
      ...state.userRoles.notifications,
    } : {};
  },
};

const actions = {
  [GET_USER_ROLES](context) {
    context.commit("setRolesInfoLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.get("/core/info")
        .then(async ({
          data
        }) => {
          resolve(data);
          context.commit("setRolesInfoLoader", false);
          StorageService.setItem(
            "current_team_id",
            data && data.team ? data.team.id : ""
          );
          StorageService.setItem(
            "current_team_role_id",
            data && data.team ? data.team.member_role_id : ""
          );
          context.commit(UPDATE_ROLES, data);

          context.commit(SET_APP_CONSTANTS, {
            assets: data.assets,
          });
          context.commit(UPDATE_INFO, data.profile);
          context.commit(SET_GLOBAL_CONTEXT_FOR_TRACKER);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.message);
          reject(error);
          context.commit("setRolesInfoLoader", false);
        });
    });
  },
  [GET_BROADCAST_INFO](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("/core/broadcast")
        .then(async ({
          data
        }) => {
          resolve(data);
          context.commit(
            "setBroadcastMessage",
            data?.results[0]?.data[0] || {}
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_PROFILE](context, dataToPost) {
    return new Promise((resolve, reject) => {
      ApiService.put("/core/profile", dataToPost)
        .then(async ({
          data
        }) => {
          resolve(data);
          // context.commit(UPDATE_INFO, dataToPost);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.message);
          reject(error);
        });
    });
  },
  [DELETE_PROFILE](context, dataToPost) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/core/profile", dataToPost)
        .then(async ({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_PROFILE_IMAGE](context) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/core/image")
        .then(async (response) => {
          const data = {
            image: "noImage",
          };
          context.commit(UPDATE_INFO, data);
          resolve(response);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.message);
          reject(error);
        });
    });
  },
  [UPDATE_PROFILE_IMAGE](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("/core/image", data)
        .then(async ({
          data
        }) => {
          context.commit(UPDATE_INFO, data);
          resolve(data);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.message);
          reject(error);
        });
    });
  },
  [SIGNIN](context, credentials) {
    ApiService.setHeader();
    context.dispatch("updateUserAxiosToken");
    return new Promise((resolve, reject) => {
      ApiService.post("/core/signin", credentials)
        .then(async ({
          data
        }) => {
          await context.dispatch(CLEAR_STORE);
          await context.commit(SET_AUTH, data.data);
          context.commit("cancelSnackbar", false);
          resolve(data);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.message);
          reject(error);
        });
    });
  },
  [SSO_AUTH](context, query) {
    return new Promise((resolve, reject) => {
      ApiService.get(
          `/core/sso?sso=${query.sso}&sig=${query.sig}&source=${
          query.source || ""
        }`
        )
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_DARK_THEME](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/core/theme", payload)
        .then(async ({
          data
        }) => {
          if (payload.name === "light") {
            context.commit("setDarKTheme", "light");
          } else {
            context.commit("setDarKTheme", "dark");
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SIGNIN_WITH_TOKEN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/core/social-auth", credentials)
        .then(async ({
          data
        }) => {
          await context.dispatch(CLEAR_STORE);
          await context.commit(SET_AUTH, data.data);
          context.commit("cancelSnackbar", false);
          resolve(data);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.message);
          reject(error);
        });
    });
  },
  [SIGNOUT](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("/core/signout")
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CLEAR_STORE](context) {
    context.commit("setInactiveUserMessage", "");
    context.commit("clearPermission");
    context.dispatch(CLEAR_APP);
    context.dispatch(CLEAR_APPS);
    context.dispatch(CLEAR_CREATE_APP);
    context.dispatch(CLEAR_DASHBOARD);
    context.dispatch(CLEAR_UPLOADS);
    context.dispatch(CLEAR_APP_AUTOCOMPLETE);
    context.commit(CLEAR_TEAM);
    context.commit("clearInvitesList");
    context.commit(CLEAR_APP_MEMBER);
    context.commit(CLEAR_NOTIFICATION_DATA);
    context.commit(CLEAR_STORAGE);
    context.commit(CLEAR_WHATS_NEW_LIST);
    context.commit(CLEAR_RELEASE_DATA);
    context.commit(CLEAR_TIMELINE_DATA);
    context.commit(CLEAR_TOKEN_LIST);
    context.commit(CLEAR_INVITE_DATA);
    context.commit(CLEAR_TEAM_MEMBER_DATA);
    context.commit("clearInvitesList", null);
  },
  [SIGNUP](context, user) {
    ApiService.setHeader();
    context.dispatch("updateUserAxiosToken");
    return new Promise((resolve, reject) => {
      ApiService.post("/core/signup", user)
        .then(({
          data
        }) => {
          // gtag_report_signup_conversion();
          resolve(data);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.message);
          reject(error);
        });
    });
  },
  [FORGOT_PASSWORD](context, email) {
    return new Promise((resolve, reject) => {
      ApiService.post("/core/forgot-password", email)
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.message);
          reject(error);
        });
    });
  },
  [RESET_PASSWORD](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/core/reset-password", credentials)
        .then(async ({
          data
        }) => {
          await context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.message);
          reject(error);
        });
    });
  },
  [REFRESH_TOKEN]() {
    return new Promise((resolve, reject) => {
      ApiService.post("/core/refresh-token", {
          refresh_token: JwtService.getRefreshToken(),
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [VERIFY_ACTIVATE](context, token) {
    context.dispatch(CLEAR_STORE);
    return new Promise((resolve, reject) => {
      ApiService.get(`/core/activate/${token}`)
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [VERIFY_CODE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/verify-code`, payload)
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [VERIFY_TOKEN](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/verify-token`, payload)
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [RESEND_TOKEN](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/resend-token`, payload)
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ACTIVATE_ACCOUNT](context, token) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/activate`, {
          token,
        })
        .then(async ({
          data
        }) => {
          await context.dispatch(CLEAR_STORE);
          await context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_ACCOUNT_VERIFY](context, token) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/profile/confirm-delete/${token}`)
        .then(async ({
          data
        }) => {
          await context.dispatch(CLEAR_STORE);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [VERIFY_RESET_PASSWORD_TOKEN](context, token) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/core/reset-password/${token}`)
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      context.dispatch("updateUserAxiosToken");
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [PURGE_AUTH_DATA](context) {
    context.commit(PURGE_AUTH);
  },
  updateUserAxiosToken(context) {
    coreApi.interceptors.request.use(function (config) {
      const token = JwtService.getToken();
      config.headers.Authorization = token ? `Bearer ${token}` : "";
      return config;
    });
    portalApi.interceptors.request.use(function (config) {
      const token = JwtService.getToken();
      config.headers.Authorization = token ? `Bearer ${token}` : "";
      return config;
    });
  },
  [UPDATE_USER_TOKEN](context, token) {
    let user = StorageService.getItem("user");
    user.token = token;
    StorageService.setItem("user", user);
    context.commit("updateUserToken", token);
    JwtService.saveToken(token);
    context.dispatch("updateUserAxiosToken");
    ApiService.setHeader();
  },
};

const mutations = {
  setFeedbackModalDetail(state, payload) {
    state.feedbackModalDetail = payload;
  },
  setSSOAuthQuery(state, payload) {
    state.ssoAuthQuery = payload || null;
  },
  updateTeamAutoArchiveSettings(state, payload) {
    let info = state?.userRoles?.team_plan?.usage?.distribution;
    info.release_expire = payload;
    state.userRoles.team_plan.usage.distribution = info;
  },
  updateUserEmail(state, payload) {
    state.user.email = payload;
  },
  setInactiveUserMessage(state, payload) {
    state.inactiveUserMessage = payload;
  },
  setBroadcastMessage(state, payload) {
    state.broadCastMessage = payload;
  },
  setDarKTheme(state, payload) {
    const info = {
      ...state.userRoles,
    };
    if (info && info.profile && info.profile.meta) {
      info.profile.meta.theme = payload;
    }
    state.userRoles = {
      ...info,
    };
  },
  setPortalUpdateInfo(state, payload) {
    state.portalUpdateInfo = payload || null;
  },
  setRedirectionUrl(state, payload) {
    state.redirectionUrl = payload || {};
  },
  setQueryTeamId(state, payload) {
    state.queryTeamId = payload;
  },
  setTeamName(state, payload) {
    let info = state.userRoles;
    if (info.team && info.team.name) {
      info.team.name = payload;
    }
    state.userRoles = info;
  },
  setNavigateTo(state, payload) {
    state.navigateTo = payload;
  },
  updateUserToken(state, payload) {
    let user = state.user;
    user.token = payload;
    state.user = user;
  },
  setAuthMessage(state, payload) {
    state.authMessage = payload;
  },
  setActivateErrorMessage(state, payload) {
    state.activateErrorMessage = payload || "";
  },
  setRolesInfoLoader(state, data) {
    state.infoLoaded = data;
  },
  updateNotificationsSetting(state, data) {
    let userData = state.userRoles;
    if (userData.profile) {
      userData.profile.notification_settings = data;
    }
    state.userRoles = userData;
  },
  updateNotification(state, data) {
    let detail = state.userRoles;
    detail.notifications = {
      alerts: data.alerts,
      invites: data.invites,
    };
    state.userRoles = {
      ...detail,
    };
  },
  updateUserPermission(state, data) {
    let detail = state.userRoles;
    detail.permissions = {
      ...data,
    };
    state.userRoles = {
      ...detail,
    };
  },
  updateTeamId(state, teamId) {
    let detail = state.userRoles;
    detail.team = {
      id: teamId,
    };
    state.userRoles = {
      ...detail,
    };
  },
  updateUserUsages(state, data) {
    let detail = state.userRoles;
    detail.team_plan = {
      ...data,
    };
    state.userRoles = {
      ...detail,
    };
  },
  clearPermission(state) {
    state.userRoles = {};
  },
  [SET_IDENTITY_FOR_TRACKER]() {
    if (user) {
      setTimeout(() => {

        //Firebase
        Vue.prototype.$analytics.setUserId(user?.id);

        //Sentry
        Sentry.setUser({
          id: user.id,
        });

        window.analytics.identify(
          user.id, {
            email: user?.email,
            name: user?.name,
          }, {
            integrations: {
              Intercom: {
                user_hash: user?.intercom_hash,
              },
            },
          }
        );

        // Mixpanel
        if (window.mixpanel) {
          // Check if we already have the same distinct_id in our localstorage or not, if not then set it
          const getStoredMixpanelDistinctId =
            StorageService.getItem("mixpanel_distinct_id") || "";
          const getMixpanelDistinctId = mixpanel?.get_distinct_id?.() || "";

          if (
            getStoredMixpanelDistinctId !== getMixpanelDistinctId &&
            getMixpanelDistinctId.trim() !== ""
          ) {
            StorageService.setItem(
              "mixpanel_distinct_id",
              getMixpanelDistinctId
            );
            mixpanel.alias(getMixpanelDistinctId, user.id);
          }
        }
      }, 100);
    }
  },
  [REMOVE_IDENTITY_FOR_TRACKER]() {

    //Firebase
    Vue.prototype.$analytics.setUserId(null);

    //Sentry
    Sentry.configureScope((scope) => scope.setUser(null));

    //Segment
    window.analytics.reset();
  },
  logError(state, payload) {
    Sentry.captureException(payload);
  },
  logEvent(state, payload) {
    window.analytics.track(payload.eventName, payload.message || {});
    Vue.prototype.$analytics.logEvent(payload.eventName, payload.message || {});
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [UPDATE_ROLES](state, data) {
    state.userRoles = data;
  },
  clearTeamId(state) {
    state.userRoles.team = {};
  },
  [UPDATE_INFO](state, data) {
    let user = state.user;
    if (data?.image) {
      if (data.image === "noImage") {
        user.image = "";
      } else {
        user.image = data.image;
      }
    }
    if (data.name) {
      user.name = data?.name;
      user.email = data?.email;
    }
    if (data.social_logins) {
      user.social_logins = data.social_logins;
    }
    state.user = user;
    StorageService.setItem("user", user);
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = null;
    StorageService.setItem("user", user);
    JwtService.saveToken(state.user.token);
    this.dispatch("updateUserAxiosToken");
    ApiService.setHeader();

    /** Firebase */
    Vue.prototype.$analytics.setUserId(state?.user?.id);
    Vue.prototype.$analytics.logEvent("signin");
    this.commit(SET_IDENTITY_FOR_TRACKER);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = null;
    StorageService.removeItem("user");
    StorageService.removeItem("roles");
    JwtService.destroyToken();
    this.commit(REMOVE_IDENTITY_FOR_TRACKER);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
