import {
  mapGetters,
  mapMutations
} from "vuex";
import Vue from "vue";
import {
  connect
} from "@/store/common/pusher-instance";
import * as Sentry from "@sentry/vue";
import apiRoutes from "@/consts/routes/apiRoutes";
import pageRoutes from "@/consts/routes/pageRoutes";
import ApiService from "@/core/services/api.service";
import {
  getQuery,
  removeEmpty,
  toNumber
} from "@/core/utilities";

const EventBus = new Vue();

export default {
  data() {
    return {
      trialDay: 14,
      apiRoutes,
      pageRoutes,
      apiService: ApiService,
      EventBus,
    };
  },
  computed: {
    ...mapGetters({
      assets: "getAssets",
      getTeamId: "getTeamId",
      getRoleID: "getRoleID",
      getIsUserDisabled: "getIsUserDisabled",
      getDataFromFeedbackSocket: "getDataFromFeedbackSocket",
    }),

    /**
     * @returns
     *
     * android: Android device.
     * ios: iOS device.
     * ios-desktop-mode: They need to change to mobile mode.
     * unknown: They need to open this page from Android or iOS device.
     *
     */
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        return "android";
      } else if (
        (/iPad|iPhone|iPod/.test(userAgent) ||
          (navigator.platform === "MacIntel" &&
            navigator.maxTouchPoints > 1)) &&
        !window.MSStream
      ) {
        return "ios";
      }

      return "unknown";
    },
  },
  methods: {
    ...mapMutations({
      notifyUserMessage: "notifyUserMessage",
      notifyInfoMessage: "notifyInfoMessage",
      notifyErrorMessage: "notifyErrorMessage",
      cancelSnackbar: "cancelSnackbar",
      setIsSocketDisconnected: "setIsSocketDisconnected",
      setPortalUpdateInfo: "setPortalUpdateInfo",
      setDataFromSocket: "setDataFromSocket",
      setDataFromFeedbackSocket: "setDataFromFeedbackSocket",
      updateDataFromFeedbackSocket: "updateDataFromFeedbackSocket",
      setReloadReleasePage: "setReloadReleasePage",
      setFeedbackLoader: "setFeedbackLoader",
      setCommentAttachmentSocketData: "setCommentAttachmentSocketData",
    }),
    connectToSocket() {
      let that = this;

      connect();
      let systemChannel = this.$pusher.subscribe("private-system");
      let teamChannel = this.$pusher.subscribe(
        `private-team-${this.getTeamId}`
      );

      systemChannel.bind("portal-update", function (data) {
        if (
          parseInt(data.version_code) >
          parseInt(process.env.VUE_APP_RELEASE_CODE)
        ) {
          that.setPortalUpdateInfo({
            showReleaseUpdate: true,
            versionInfo: data,
          });
        }
      });
      teamChannel.bind("release-status", function (data) {
        that.setDataFromSocket(data);
      });
      teamChannel.bind("attachment-status", function (data) {
        that.setCommentAttachmentSocketData(data);
        that.setFeedbackLoader(false);
        if (that.getDataFromFeedbackSocket.length) {
          let ids = that.getDataFromFeedbackSocket.map((x) => x.id);
          if (ids.includes(data.id)) {
            that.updateDataFromFeedbackSocket(data);
          } else {
            that.setDataFromFeedbackSocket(data);
          }
        } else {
          that.setDataFromFeedbackSocket(data);
        }
      });
    },
    getErrorMessage(err) {
      if (err && err.message) {
        return JSON.parse(err.message);
      } else {
        return null;
      }
    },
    getAppImage(image, id) {
      if (image || id) {
        if (id) {
          return (
            image?.image ||
            `/media/defaults/${
              this.$vuetify.theme.dark ? "app_default_light" : "app_default"
            }.svg`
          );
          // return `${this.assets ? this.assets.url : ''}${this.assets ? this.assets.app : ''}${id}/image/${image}`;
        } else {
          return (
            image?.image ||
            `/media/defaults/${
              this.$vuetify.theme.dark ? "app_default_light" : "app_default"
            }.svg`
          );
          // return `${this.assets.url}${this.assets ? this.assets.app : ''}${image.id}/image/${image.image}`;
        }
      }
    },
    getStorageImg(name) {
      return "/media/svg/storage/" + name;
    },
    getProfileImage(image) {
      return image;
      // return `${this.assets ? this.assets.url : ""}${
      //   this.assets ? this.assets.user : ""
      // }image/${image}`;
    },
    replaceDefaultUserImage(e) {
      e.target.src = "/media/defaults/member.jpg";
    },
    replaceDefaultAppImage(e) {
      e.target.src = "/media/defaults/icon-100.png";
    },
    onContactus() {
      if (window && window.Intercom) {
        window.Intercom("show");
        this.logEvent("contact us modal", {
          status: "success",
          action: "contact_us",
        });
      } else {
        window.open("https://testapp.io/contact", "_blank");
        this.logEvent("redirect to landing page", {
          status: "success",
          action: "contact_us",
        });
      }
    },
    openIntercomChat(contactInfo) {
      if (window.Intercom) {
        window.Intercom("showNewMessage", contactInfo.message);
      } else {
        this.fallbackToEmail(contactInfo);
      }
    },
    fallbackToEmail(contactInfo) {
      const emailAddress = "support@testapp.io";
      const subject = encodeURIComponent(contactInfo.subject);
      const body = encodeURIComponent(contactInfo.message);
      const mailtoLink = `mailto:${emailAddress}?subject=${subject}&body=${body}`;

      window.location.href = mailtoLink;
    },
    logError(error) {
      Sentry.captureException(error);
    },
    logEvent(eventName, message) {
      // for segment
      window.analytics.track(eventName, message || {});
      // for firebase analytics
      Vue.prototype.$analytics.logEvent(eventName, message || {});
    },
    async copy(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.notifyUserMessage({
          message: "Copied successfully",
          timeout: true,
        });
      } catch (error) {
        this.logError({
          message: "An error occurred while copying",
          timeout: true,
        });
        this.notifyErrorMessage({
          message: "An error occurred while copying",
          timeout: true,
        });
      }
    },
    removeEmpty,
    getQuery,
    toNumber,
    copyToClipboard(text) {
      const btn = document.createElement("button");
      btn.setAttribute("data-clipboard-text", text);
      document.body.appendChild(btn);

      const clipboard = new window.ClipboardJS(btn);
      clipboard.on("success", (e) => {
        this.EventBus.$emit("notification", {
          color: "success",
          text: "Copied to clipboard",
        });
        document.body.removeChild(btn);
        clipboard.destroy();
      });

      clipboard.on("error", (e) => {
        this.EventBus.$emit("notification", {
          color: "success",
          text: "Failed to copy to clipboard",
        });
        document.body.removeChild(btn);
        clipboard.destroy();
      });

      btn.click();
    },
  },
};
